<template>
  <div class="addinvestigate">
    <div class="switch_tab">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>

      <div v-if="typeIndex == 1" class="essential_information">
        <div class="top_info" v-if="id">
          <span>
            <img src="../../../assets/image/Volume Up.png" alt="" />
          </span>
          <div class="top_time">
            <div>
              <span style="color: #75757d">最新更新时间：</span>
              <span>{{ topinfo.updateTime }}</span>
            </div>

            <div>
              <span style="color: #75757d">更新人：</span>
              <span>{{ topinfo.updateName }}</span>
            </div>
          </div>
        </div>
        <h4>基本信息</h4>
        <div class="form_essential">
          <el-form ref="form" :model="form" :rules="rules" label-width="110px">
            <!-- <div class="el_upload"> -->
            <el-form-item label="封面图:" prop="projectImg">
              <div class="com_upload">
                <img-big-upload
                  :imageShow.sync="form.projectImg"
                  :addTitleShow="false"
                  @removeImg="removeImgDian"
                  @uploadOneImgShow="uploadOneImgShowDian"
                />
              </div>
              <div class="img_tips">
                <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
              </div>
            </el-form-item>
            <!-- </div> -->
            <div class="name_form">
              <el-form-item label="项目名称:" prop="projectName">
                <el-input v-model="form.projectName" placeholder="请输入项目名称" />
              </el-form-item>
              <el-form-item label="项目目的:" prop="projectPurpose">
                <el-select v-model="form.projectPurpose" placeholder="请选择项目目的">
                  <el-option
                    v-for="item in purposelist"
                    :key="item.id"
                    :label="item.val"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="项目时间:" prop="startTime">
              <el-date-picker
                v-model="projectime"
                unlink-panels
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                @change="timeChange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="项目地点:" prop="city">
              <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="width: 10px; display: inline-block"></div>
              <el-select v-model="form.city" placeholder="请选择地区" @focus="citychange">
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="需求提交人:" prop="createId">
              <el-select
                v-model="form.createId"
                placeholder="请选择需求提交人"
                @change="memberchange"
              >
                <el-option
                  v-for="item in memberlist"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="name_form">
              <el-form-item label="联系方式:" prop="">
                <el-input v-model="form.phone" placeholder="请输入联系方式" />
              </el-form-item>
            </div>
            <el-form-item label="考察领域">
              <el-input
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10 }"
                placeholder="展示小程序用户填写的考察领域"
                v-model="form.areasInvestigationKeyword"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="战略新兴产业标签:" prop="areasInvestigation">
              <div class="general_inputs">
                <div class="input_flex" v-for="(item, index) in list" :key="index">
                  <div class="name_form">
                    <el-cascader
                      v-model="item.areas"
                      :options="operaList"
                      :props="{
                        label: 'codeName',
                        value: 'codeName',
                        children: 'codeMasterFieldResultList',
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      @change="handleChange(index)"
                    ></el-cascader>
                  </div>
                  <div style="display: flex">
                    <!-- <el-input
                      v-model="item.general"
                      placeholder="请输入权重"
                      @change="handleChange(index)"
                    />
                    <span style="font-size: 14px; color: #9b9a9a">%</span> -->
                    <span v-if="index == 0" style="color: #4e93fb" @click="addareas"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delareas(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="行业标签:" prop="industryTag">
              <div class="general_inputs">
                <div class="input_flex" v-for="(item, index) in listOne" :key="index">
                  <div class="name_form">
                    <el-cascader
                      v-model="item.areas"
                      :options="listIndustry"
                      :props="{
                        label: 'codeName',
                        value: 'codeName',
                        children: 'codeMasterFieldResultList',
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      @change="handleChangeHa(index)"
                    ></el-cascader>
                  </div>
                  <div style="display: flex">
                    <!-- <el-input
                      v-model="item.general"
                      placeholder="请输入权重"
                      @change="handleChange(index)"
                    />
                    <span style="font-size: 14px; color: #9b9a9a">%</span> -->
                    <span v-if="index == 0" style="color: #4e93fb" @click="addareasHa"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delareasHa(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="技术标签:" prop="aiTag">
              <div class="general_inputs">
                <div class="input_flex" v-for="(item, index) in listTwo" :key="index">
                  <div class="name_form">
                    <el-cascader
                      v-model="item.areas"
                      :options="listAi"
                      :props="{
                        label: 'codeName',
                        value: 'codeName',
                        children: 'codeMasterFieldResultList',
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      @change="handleChangeJi(index)"
                    ></el-cascader>
                  </div>
                  <div style="display: flex">
                    <!-- <el-input
                      v-model="item.general"
                      placeholder="请输入权重"
                      @change="handleChange(index)"
                    />
                    <span style="font-size: 14px; color: #9b9a9a">%</span> -->
                    <span v-if="index == 0" style="color: #4e93fb" @click="addareasJi"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delareasJi(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="考察人员:" prop="inspectNumber">
              <el-input-number
                v-model="form.inspectNumber"
                :min="1"
                :max="10000"
                label="描述文字"
                @change="changeNum"
              ></el-input-number>
              <div style="margin-top: 20px" class="general_input">
                <div
                  class="input_flex"
                  v-for="(item, index) in form.investigationPersonnelList"
                  :key="index"
                >
                  <div style="display: flex">
                    <el-input v-model="item.name" placeholder="请输入姓名" />
                    <div style="padding: 0px 10px"></div>
                    <el-input v-model="item.position" placeholder="请输入职位" />
                    <div style="padding: 0px 10px"></div>
                    <el-input v-model="item.principal" placeholder="请输入政府对接负责人" />
                    <span v-if="index == 0" style="color: #4e93fb" @click="addgeneral"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="附件:" prop="">
              <div
                class="name_form"
                style="width: 700px; display: flex; justify-content: space-between"
              >
                <el-input v-model="form.accessory" placeholder="" />
                <el-upload
                  action
                  class="upload-demo"
                  ref="upload"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :http-request="upload"
                >
                  <el-button slot="trigger" size="mini" type="danger">选取文件</el-button>
                </el-upload>
              </div>
            </el-form-item>
            <div class="applets_share">
              <h4>小程序分享链接标题</h4>
              <el-form-item label="分享链接标题:" prop="shareTitle">
                <el-input v-model="form.shareTitle" placeholder="请输入小程序分享链接信息" />
              </el-form-item>
              <el-form-item label="分享链接图片:" prop="sharePictures">
                <div class="com_upload">
                  <img-big-upload
                    :imageShow.sync="form.sharePictures"
                    :addTitleShow="false"
                    @removeImg="removeImgDians"
                    @uploadOneImgShow="uploadOneImgShowDians"
                  />
                </div>
                <div class="img_tips">
                  <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="margin-top: 30px" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="clcans">取消</el-button>
        </div>
      </div>
      <div class="label_management" v-if="typeIndex == 2">
        <div class="input-with">
          <el-input
            placeholder="请输入参访嘉宾名称"
            v-model="queryInfo.name"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
          <div style="display: flex; justify-content: left">
            <el-button type="primary" @click="addClick">新增嘉宾</el-button>
            <div style="margin-left: 20px" class="top_info" v-if="id">
              <span>
                <img src="../../../assets/image/Volume Up.png" alt="" />
              </span>
              <div class="top_time">
                <div>
                  <span style="color: #75757d">最新更新时间：</span>
                  <span>{{ topinfo.updateTime }}</span>
                </div>

                <div>
                  <span style="color: #75757d">更新人：</span>
                  <span>{{ topinfo.updateName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-table
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >:reserve-selection="true" />
          <el-table-column label="编号" align="center" type="index"></el-table-column>
          <el-table-column label="姓名" align="center" prop="name">
            <template v-slot="{ row }">
              <span>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="companyName" align="center"></el-table-column>
          <el-table-column
            label="企业职务"
            prop="enterprisePosition"
            align="center"
          ></el-table-column>

          <el-table-column label="嘉宾联系电话" prop="phone" align="center"></el-table-column>

          <el-table-column label="操作人" prop="createme" align="center"></el-table-column>

          <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="260px"
          >
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="editpartic(scope.row)"
                >编辑
              </el-button>
              <!-- <el-button style="color: #ff4646" type="text" @click="delpartic(scope.row)"
                >删除
              </el-button> -->
              <el-button
                style="color: #ff4646"
                type="text"
                v-if="scope.row.isDelete == 0 || scope.row.isDelete == null"
                class="edit"
                @click="delpartic(scope.row)"
                >冻结</el-button
              >
              <el-button
                style="color: #ff4646"
                type="text"
                v-if="scope.row.isDelete == 1"
                class="edit"
                @click="delpartic(scope.row)"
                >激活</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog title="嘉宾管理" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="parti" :model="participants" :rules="prules" label-width="90px">
        <el-form-item label="姓名:" prop="name">
          <el-input v-model="participants.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="选择企业:" prop="companyName">
          <el-select v-model="participants.companyName" placeholder="请输入参访企业">
            <el-option
              v-for="(item, index) in buildlist"
              :key="index"
              :label="item.companyName"
              :value="item.companyName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业职务:" prop="">
          <el-input v-model="participants.enterprisePosition" placeholder="请输入企业职务" />
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input v-model="participants.phone" placeholder="请输入联系方式" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setsubmit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdemand,
  adddemand,
  editdemand,
  getByFatherIdt,
  getMemberAll,
  getparticipantsList,
  addparticipants,
  getCompanyList,
  delparticipants,
  getparticipants,
  editparticipants,
  getFieldList,
  getProvince,
  getdemandtop
} from '@/api/demand'
import { uploadAdmin } from '@/api/common'
export default {
  name: 'addinvestigate',
  computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      active: 2,
      //tab切换
      typeIndex: 1,
      operatingList: [], //地区
      form: {
        industryTag: '',
        aiTag: '',
        areasInvestigationKeyword: '',
        accessory: '',
        areasInvestigation: null,
        province: null,
        city: '',
        endTime: '',
        id: null,
        inspectNumber: 1,
        investigationPersonnelList: [
          {
            id: null,
            inspectDemandId: null,
            name: '',
            position: '',
            principal: ''
          }
        ],
        projectImg: '', //封面图
        projectName: '', //项目名称
        projectPurpose: '', //目的
        projectStatus: '1',
        sharePictures: '',
        shareTitle: '',
        startTime: ''
      }, //表单收集
      purposelist: [
        {
          id: 1,
          val: '政府招商'
        },
        {
          id: 2,
          val: '项目考察'
        },
        {
          id: 3,
          val: '学习交流'
        },
        {
          id: 4,
          val: '业务对接'
        },
        {
          id: 5,
          val: '商业合作'
        },
        {
          id: 6,
          val: '项目落地'
        },
        {
          id: 7,
          val: '其他'
        }
      ], //项目目的
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        inspectDemandId: this.$route.query.id * 1,
        name: null //嘉宾名称
      }, //参访嘉宾一览查询
      total: 0, //参访嘉宾条数
      listData: [], //嘉宾数
      participants: {
        companyName: '',
        enterprisePosition: '',
        id: null,
        inspectDemandId: this.$route.query.id * 1,
        name: '',
        phone: ''
      }, //新增嘉宾
      open: false,
      buildlist: [], ////企业下拉框

      orderType: [
        {
          id: 1,
          code: '基本信息'
        },
        {
          id: 2,
          code: '参访嘉宾'
        }
      ],
      filename: '', //附件
      projectime: [],
      rules: {
        projectImg: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'change' }],
        projectPurpose: [{ required: true, message: '请选择项目目的', trigger: 'change' }],
        startTime: [{ required: true, message: '请勾选时间', trigger: 'change' }],
        createId: [{ required: true, message: '请添加需求提交人', trigger: 'change' }],
        city: [{ required: true, message: '请选择地区', trigger: 'change' }],
        areasInvestigation: [
          { required: true, message: '请添加战略新兴产业标签', trigger: 'change' }
        ],
        industryTag: [{ required: true, message: '请添加行业标签', trigger: 'change' }],
        aiTag: [{ required: true, message: '请添加技术标签', trigger: 'change' }],
        inspectNumber: [{ required: true, message: '请添加考察人数', trigger: 'change' }]
      },
      prules: {
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        companyName: [{ required: true, message: '请选择企业', trigger: 'change' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
      },
      operatingLists: [],
      memberlist: [],
      operaList: [], //考察领域 --战略新兴产业标签
      //行业标签
      listIndustry: [],
      //技术标签
      listAi: [],
      //考察领域 --战略新兴产业标签
      list: [
        {
          areas: [],
          general: ''
        }
      ],
      //行业标签
      listOne: [
        {
          areas: [],
          general: ''
        }
      ],
      //技术标签
      listTwo: [
        {
          areas: [],
          general: ''
        }
      ],
      topinfo: {}, //更新时间
      flagtime: null
    }
  },

  created() {
    this.incity()
    //考察领域
    this.getFieldList()
    //行业标签
    this.getIndustryList()
    //技术标签
    this.getAiList()
    if (this.$route.query.flag * 1) {
      this.typeIndex = 2
      this.search()
    } else {
      this.getMemberAll()
    }
    if (this.id) {
      this.getdemandtop()
      this.getdemand()
    }
  },
  methods: {
    //最近更新时间
    async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.id })
      if (res.data.resultCode == 200) {
        this.topinfo = res.data.data
      }
    },
    //考察领域战略新兴产业标签
    async getFieldList() {
      const res = await getFieldList({ codeType: '010' })
      this.operaList = res.data.data
    },

    //行业标签
    async getIndustryList() {
      const res = await getFieldList({ codeType: '011' })
      this.listIndustry = res.data.data
    },

    //技术标签
    async getAiList() {
      const res = await getFieldList({ codeType: '012' })
      this.listAi = res.data.data
    },
    //查询秘书长会员用户
    async getMemberAll() {
      const res = await getMemberAll()
      if (res.data.resultCode == 200) {
        this.memberlist = res.data.data
        this.memberlist.forEach((o) => {
          if (this.form.createId == o.id) {
            this.form.phone = o.phone
          }
        })
      }
    },
    //考察领域不能重复添加
    handleChange(index) {
      if (this.list.length > 1) {
        for (var i = 0; i < this.list.length - 1; i++) {
          if (i != index) {
            if (this.list[i].areas[1] == this.list[index].areas[1]) {
              this.list[index].areas = []
              this.$message.warning('请不要重复添加！')
            }
          }
        }
      }

      this.form.areasInvestigation = JSON.stringify(this.list)
    },

    //行业标签不能重复添加
    handleChangeHa(index) {
      if (this.listOne.length > 1) {
        for (var i = 0; i < this.listOne.length - 1; i++) {
          if (i != index) {
            if (this.listOne[i].areas[1] == this.listOne[index].areas[1]) {
              this.listOne[index].areas = []
              this.$message.warning('请不要重复添加！')
            }
          }
        }
      }

      this.form.industryTag = JSON.stringify(this.listOne)
    },
    addareasHa() {
      this.listOne.push({
        areas: [],
        general: ''
      })
    },
    delareasHa(index) {
      this.listOne.splice(index, 1)
      this.form.industryTag = JSON.stringify(this.listOne)
    },

    //技术标签不能重复添加
    handleChangeJi(index) {
      if (this.listTwo.length > 1) {
        for (var i = 0; i < this.listTwo.length - 1; i++) {
          if (i != index) {
            if (this.listTwo[i].areas[1] == this.listTwo[index].areas[1]) {
              this.listTwo[index].areas = []
              this.$message.warning('请不要重复添加！')
            }
          }
        }
      }

      this.form.aiTag = JSON.stringify(this.listTwo)
    },
    addareasJi() {
      this.listTwo.push({
        areas: [],
        general: ''
      })
    },
    delareasJi(index) {
      this.listTwo.splice(index, 1)
      this.form.aiTag = JSON.stringify(this.listTwo)
    },

    memberchange() {
      this.memberlist.forEach((o) => {
        if (this.form.createId == o.id) {
          this.form.phone = o.phone
        }
      })
    },
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          this.getByFatherIdt(o.code)
        }
      })
    },
    //获取信息
    async getdemand() {
      const res = await getdemand(this.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        this.getMemberAll()
        //考察领域 -- 战略新兴产业标签
        if (this.form.areasInvestigation) {
          this.list = JSON.parse(this.form.areasInvestigation)
        } else {
          this.list = [
            {
              areas: [],
              general: ''
            }
          ]
        }
        //技术标签
        if (this.form.aiTag) {
          this.listTwo = JSON.parse(this.form.aiTag)
        } else {
          this.listTwo = [
            {
              areas: [],
              general: ''
            }
          ]
        }
        //行业标签s
        if (this.form.industryTag) {
          this.listOne = JSON.parse(this.form.industryTag)
        } else {
          this.listOne = [
            {
              areas: [],
              general: ''
            }
          ]
        }

        if (this.form.sharePictures == null) {
          this.form.sharePictures = ''
        }
        if (this.form.projectImg == null) {
          this.form.projectImg = ''
        }
        if (this.form.investigationPersonnelList.length == 0) {
          this.form.investigationPersonnelList = [
            {
              id: null,
              inspectDemandId: null,
              name: '',
              position: '',
              principal: ''
            }
          ]
        }
        this.projectime.push(this.form.startTime)
        this.projectime.push(this.form.endTime)
      }
    },
    //tab切换
    storeClick(id) {
      this.search()
      this.typeIndex = id
    },
    //参访嘉宾列表
    async search() {
      const res = await getparticipantsList(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
      }
    },
    //获取可以新增的企业
    async getcompany() {
      const res = await getCompanyList({ companyName: '' })
      if (res.data.resultCode == 200) {
        this.buildlist = res.data.data
      }
    },
    //新增参访嘉宾
    addClick() {
      this.getcompany()
      this.open = true
      this.participants = {
        companyName: '',
        enterprisePosition: '',
        id: null,
        inspectDemandId: this.$route.query.id * 1,
        name: '',
        phone: ''
      }
    },
    //编辑参访嘉宾
    editpartic(row) {
      this.getparticipants(row.id)
      this.open = true
    },
    //查询嘉宾详细
    async getparticipants(id) {
      const res = await getparticipants(id)
      if (res.data.resultCode == 200) {
        this.participants = res.data.data
      }
    },
    cancel() {
      this.open = false
    },
    setsubmit() {
      this.$refs['parti'].validate((valid) => {
        if (valid) {
          if (this.flagtime) {
            clearTimeout(this.flagtime)
          }
          this.flagtime = setTimeout(() => {
            this.submit()
          }, 1000)
        }
      })
    },
    async submit() {
      if (this.participants.id == null) {
        addparticipants(this.participants).then((response) => {
          if (response.data.resultCode == 200) {
            this.$message.success('新增成功')
            this.open = false
            this.search()
          }
        })
      } else {
        editparticipants(this.participants).then((response) => {
          if (response.data.resultCode == 200) {
            this.$message.success('编辑成功')
            this.open = false
            this.search()
          }
        })
      }
    },
    //删除嘉宾
    delpartic(row) {
      this.$alert('您是否确认操作？')
        .then(function () {
          return delparticipants(row)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //考察人员联动
    changeNum() {
      let num
      num = this.form.inspectNumber - this.form.investigationPersonnelList.length
      if (num > 0) {
        for (var i = 0; i < num; i++) {
          this.form.investigationPersonnelList.push({
            id: null,
            inspectDemandId: null,
            name: '',
            position: '',
            principal: ''
          })
        }
      } else {
        for (var a = 0; a > num; a--) {
          this.form.investigationPersonnelList.splice(a, 1)
        }
      }
    },
    //添加考察人员
    addgeneral() {
      this.form.investigationPersonnelList.push({
        id: null,
        inspectDemandId: null,
        name: '',
        position: '',
        principal: ''
      })
      this.form.inspectNumber = this.form.investigationPersonnelList.length
    },
    //删除考察人员
    delgeneral(index) {
      this.form.investigationPersonnelList.splice(index, 1)
      this.form.inspectNumber = this.form.investigationPersonnelList.length
    },
    addareas() {
      this.list.push({
        areas: [],
        general: ''
      })
    },
    delareas(index) {
      this.list.splice(index, 1)
      this.form.areasInvestigation = JSON.stringify(this.list)
    },
    //时间
    timeChange() {
      if (this.projectime.length > 1) {
        this.form.startTime = this.projectime[0]
        this.form.endTime = this.projectime[1]
      } else {
        this.form.startTime = ''
        this.form.endTime = ''
      }
    },
    //上传pdf
    async beforeUpload(file) {
      this.file = file
      this.filename = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await uploadAdmin(form)
      if (res.data.resultCode === 200) {
        this.form.accessory = res.data.data
      }
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.projectImg = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.projectImg = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.sharePictures = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.sharePictures = ''
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            adddemand(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            editdemand(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clcans() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .form_essential .el-textarea {
  width: 554px !important;
}

::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}

::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.addinvestigate {
  .top_info {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 17px;
    }
    .top_time {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      width: 356.84px;
      height: 38px;
      background: #fafafa;
      border-radius: 8px;
      line-height: 38px;
    }
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    .storeCenter_item_top_center {
      width: 600px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
      }

      .title {
        margin: 0px 0px 0px 10px;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
          padding: 4px 8px;
          color: #2e7aff;
          background: #e1ecff;
          border-radius: 4px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;
    .store_table {
      padding: 20px 30px;
      width: 100%;
      overflow: hidden;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;

      .store_table_item {
        padding: 0px 20px;
        overflow: hidden;
        line-height: 48px;
        box-sizing: border-box;
        color: #1f2d3d;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
      }

      .active {
        border-bottom: 2px solid #4e93fb;
        color: #4e93fb;
        background-color: #fff;
      }
    }
    .essential_information {
      padding: 10px 40px;
      .general_inputs {
        width: 605px;
        border: 1px solid #d4d4d4;
        padding: 14px 20px;
        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        margin: 0;
        font-size: 12px;

        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 20px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
